var exports = {};
const IMPORT_REGEX = /^import\s/;
const EXPORT_REGEX = /^export\s/;
const EXPORT_DEFAULT_REGEX = /^export default\s/;
const STARTS_WITH_CAPITAL_LETTER_REGEX = /^[A-Z]/;
const EMPTY_NEWLINE = "\n\n";
const COMMENT_OPEN = "<!--";
const COMMENT_CLOSE = "-->";

const isImport = text => IMPORT_REGEX.test(text);

const isExport = text => EXPORT_REGEX.test(text);

const isExportDefault = text => EXPORT_DEFAULT_REGEX.test(text);

const isImportOrExport = text => isImport(text) || isExport(text);

const isComment = str => str.startsWith(COMMENT_OPEN) && str.endsWith(COMMENT_CLOSE);

const getCommentContents = str => str.slice(COMMENT_OPEN.length, -COMMENT_CLOSE.length);

const startsWithCapitalLetter = str => STARTS_WITH_CAPITAL_LETTER_REGEX.test(str);

const paramCase = string => string.replace(/([a-z0-9])([A-Z])/g, "$1-$2").replace(/([a-z])([0-9])/g, "$1-$2").toLowerCase();

const toTemplateLiteral = text => {
  const escaped = text.replace(/\\(?!\$)/g, "\\\\") // Escape all "\" to avoid unwanted escaping in text nodes
  // and ignore "\$" since it's already escaped and is common
  // with prettier https://github.com/mdx-js/mdx/issues/606
  .replace(/`/g, "\\`") // Escape "`"" since
  .replace(/(\\\$)/g, "\\$1") // Escape \$ so render it as it is
  .replace(/(\\\$)(\{)/g, "\\$1\\$2") // Escape \${} so render it as it is
  .replace(/\$\{/g, "\\${"); // Escape ${} in text so that it doesn't eval

  return "{`" + escaped + "`}";
};

exports.EMPTY_NEWLINE = EMPTY_NEWLINE;
exports.isImport = isImport;
exports.isExport = isExport;
exports.isExportDefault = isExportDefault;
exports.isImportOrExport = isImportOrExport;
exports.startsWithCapitalLetter = startsWithCapitalLetter;
exports.isComment = isComment;
exports.getCommentContents = getCommentContents;
exports.paramCase = paramCase;
exports.toTemplateLiteral = toTemplateLiteral;
export default exports;
const _EMPTY_NEWLINE = exports.EMPTY_NEWLINE,
      _isImport = exports.isImport,
      _isExport = exports.isExport,
      _isExportDefault = exports.isExportDefault,
      _isImportOrExport = exports.isImportOrExport,
      _startsWithCapitalLetter = exports.startsWithCapitalLetter,
      _isComment = exports.isComment,
      _getCommentContents = exports.getCommentContents,
      _paramCase = exports.paramCase,
      _toTemplateLiteral = exports.toTemplateLiteral;
export { _EMPTY_NEWLINE as EMPTY_NEWLINE, _isImport as isImport, _isExport as isExport, _isExportDefault as isExportDefault, _isImportOrExport as isImportOrExport, _startsWithCapitalLetter as startsWithCapitalLetter, _isComment as isComment, _getCommentContents as getCommentContents, _paramCase as paramCase, _toTemplateLiteral as toTemplateLiteral };